<template>
  <div
    ref="eventSlideshow"
    class="event-slideshow"
    :style="`--eventSlideshowBackgroundColor: ${formData.backgroundColor}`"
    @mousemove="showCloseButton"
  >
    <div v-if="!slideshowStarted" class="event-slideshow__settings">
      <h2 class="event-slideshow__settings-title">
        {{ $t('slideshow_settings_title') }}
      </h2>
      <app-alert
        v-if="error"
        type="error"
        class="event-slideshow__error"
      >
        {{ error }}
      </app-alert>
      <form class="event-slideshow__form" @submit.prevent="startSlideshow">
        <app-input
          id="timeoutInSec"
          v-model="formData.timeoutInSec"
          :label="$t('slideshow_settings_item_show_seconds')"
          type="number"
          :rules="rules.timeoutInSec"
          :error="v$.timeoutInSec.$errors?.[0]?.$message"
          autocomplete="off"
          class="event-slideshow__input"
          @blur="v$.timeoutInSec.$touch"
        />
        <app-input
          id="backgroundColor"
          v-model="formData.backgroundColor"
          :label="$t('slideshow_settings_background_color')"
          type="color"
          class="event-slideshow__input"
        />
        <div class="event-slideshow__toggles">
          <app-toggle
            id="showImages"
            v-model="formData.showImages"
            name="show-images"
            :label="$t('show_images')"
            class="event-slideshow__settings-toggle"
          />
          <app-toggle
            id="showVideos"
            v-model="formData.showVideos"
            name="show-videos"
            :label="$t('show_videos')"
            class="event-slideshow__settings-toggle"
          />
        </div>
        <div class="event-slideshow__buttons">
          <app-button
            light
            :disabled="!formData.showImages && !formData.showVideos"
            :class="{
              'event-slideshow__button--disabled': !formData.showImages && !formData.showVideos
            }"
            type="submit"
          >
            {{ $t('start_slideshow') }}
          </app-button>
          <app-button
            light
            secondary
            @click="$emit('close')"
          >
            {{ $t('close') }}
          </app-button>
        </div>
      </form>
    </div>
    <template v-else>
      <transition name="fade-long" mode="out-in">
        <event-slideshow-item
          v-if="slideshowStarted && mediaItemToShow"
          :key="mediaItemToShow.id + slideshowIterations"
          :image="mediaItemToShow"
          :slide-timeout-in-ms="slideTimeoutInMs"
          class="event-slideshow__image"
          @loaded="handleSlideshowItemLoaded"
          @error="restartSlideshow"
        />
      </transition>
    </template>
    <h2 v-if="!slideshowStarted" class="event-slideshow__title">
      {{ $t('slideshow_title') }}
    </h2>
    <transition name="fade-long">
      <button
        v-show="closeButtonIsVisible || !slideshowStarted"
        class="event-slideshow__close"
        @click="closeSlideShow"
      >
        <icon name="line-md:close" class="event-slideshow__close-icon" />
      </button>
    </transition>
    <text-logo show-extension class="event-slideshow__text-logo" />
  </div>
</template>

<script setup>
  import { useImagesStore } from '~/stores/images'
  import { storeToRefs } from 'pinia'
  import debounce from 'lodash.debounce'
  import { required, minValue } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  import { Event } from '~/lib/models/event'
  import { useSlideshowSettingStore } from '~/stores/slideshow-settings'

  const emit = defineEmits(['close'])

  const props = defineProps({
    event: {
      type: Event,
      default: undefined,
    },
  })

  const { t } = useI18n()
  const { $imagesService } = useNuxtApp()
  const imagesStore = useImagesStore()
  const {
    images: allMedia,
    hasMorePages,
  } = storeToRefs(imagesStore)
  const {
    formData: storedFormData,
    setFormData: setStoredFormData,
  } = useSlideshowSettingStore()

  const fileFilter = computed(() => {
    if (!formData.showImages) {
      return 'videos'
    }

    if (!formData.showVideos) {
      return 'images'
    }

    return null
  })
  const formData = reactive({
    timeoutInSec: 5,
    backgroundColor: '#000000',
    showImages: true,
    showVideos: true,
  })
  const rules = computed(() => ({
    timeoutInSec: { required, minValue: minValue(1) },
  }))
  const error = ref(null)
  const closeButtonIsVisible = ref(true)
  const hideCloseButtonDebounced = debounce(hideCloseButton, 2500)

  const v$ = useVuelidate(rules, formData)

  const eventSlideshow = ref(null)
  const currentIndex = ref(0)
  const slideshowStarted = ref(false)
  const slideshowIterations = ref(0)
  let wakeLock = null

  const slideTimeoutInMs = computed(() => {
    const formattedTimeoutInSec = Number(formData.timeoutInSec)

    if (isNaN(formattedTimeoutInSec)) {
      return 1000
    }

    return formattedTimeoutInSec * 1000
  })
  const timeoutReference = ref(0)

  const images = computed(() => allMedia.value.filter(image => !image?.mimeType?.match('video.*')))
  const videos = computed(() => allMedia.value.filter(image => !image?.mimeType?.match('image.*')))
  const mediaItemsToShow = computed(() => {
    if (!formData.showImages && !formData.showVideos) {
      return []
    }

    if (!formData.showImages) {
      return videos.value
    }

    if (!formData.showVideos) {
      return images.value
    }

    return allMedia.value
  })

  const mediaItemToShow = computed(() => mediaItemsToShow.value[currentIndex.value] ?? null)
  const nextMediaItemToShow = computed(() => mediaItemsToShow.value[currentIndex.value + 1] ?? null)

  onMounted(() => {
    // Set stored data
    Object.assign(formData, storedFormData)
    openFullscreen()
  })

  onBeforeUnmount(() => {
    clearTimeout(timeoutReference.value)
    closeFullscreen()
  })

  function openFullscreen() {
    if (eventSlideshow.value?.requestFullscreen) {
      eventSlideshow.value.requestFullscreen()
    } else if (eventSlideshow.value?.webkitRequestFullscreen) { /* Safari */
      eventSlideshow.value.webkitRequestFullscreen()
    } else if (eventSlideshow.value?.msRequestFullscreen) { /* IE11 */
      eventSlideshow.value.msRequestFullscreen()
    }
  }

  function closeFullscreen() {
    if (eventSlideshow.value?.exitFullscreen) {
      eventSlideshow.value?.exitFullscreen()
    } else if (eventSlideshow.value?.webkitExitFullscreen) { /* Safari */
      eventSlideshow.value?.webkitExitFullscreen()
    } else if (eventSlideshow.value?.msExitFullscreen) { /* IE11 */
      eventSlideshow.value?.msExitFullscreen()
    }
  }

  function closeSlideShow() {
    enableScreenSleep()
    emit('close')
  }

  // Loaded media item triggers next item on load
  async function startSlideshow() {
    showCloseButton.value = false
    error.value = null
    const formIsValid = await v$.value.$validate()

    if (!formIsValid) {
      return
    }

    // Store formData in store
    setStoredFormData(formData)

    await $imagesService.getImages({ event: props.event, page: 0, filter: fileFilter.value })

    currentIndex.value = 0
    slideshowStarted.value = true
    await preventScreenSleep()

    if (!mediaItemToShow.value) {
      slideshowStarted.value = false
      error.value = getSlideshowErrorMessage()
    }
  }

  async function preventScreenSleep() {
    if ('wakeLock' in navigator) {
      try {
        wakeLock = await navigator.wakeLock.request('screen')
      } catch (err) {
        console.error(`Failed to request screen wake lock: ${err}`)
        wakeLock = null
      }
    } else {
      console.warn('navigator.wakeLock is not available')
    }
  }

  function enableScreenSleep() {
    if (wakeLock) {
      try {
        wakeLock.release()
      } catch (err) {
        console.error(`Failed to release screen wake lock: ${err}`)
      } finally {
        wakeLock = null
      }
    }
  }

  function getSlideshowErrorMessage() {
    if (!formData.showImages) {
      return t('slideshow_error_no_videos')
    }

    if (!formData.showVideos) {
      return t('slideshow_error_no_images')
    }

    return t('slideshow_error_no_media')
  }

  async function setNextItem(timeoutInMs) {
    const fetchAndSetImages = async () => {
      await $imagesService.getImages({ event: props.event, page: 0, filter: fileFilter.value })
      currentIndex.value = 0
      slideshowIterations.value++
    }

    const handleTimeout = (callback) => {
      timeoutReference.value = setTimeout(callback, timeoutInMs)
    }

    // Has next item
    if (nextMediaItemToShow.value) {
      return handleTimeout(() => {
        currentIndex.value++
      })
    }

    // Has no next and has no more pages, start over
    if (!hasMorePages.value) {
      return handleTimeout(fetchAndSetImages)
    }

    await $imagesService.fetchNewPage({ event: props.event, filter: fileFilter.value })
    return setNextItem(timeoutInMs)
  }

  function handleSlideshowItemLoaded(payload) {
    setNextItem(payload)
  }

  function restartSlideshow() {
    startSlideshow()
  }

  function showCloseButton() {
    closeButtonIsVisible.value = true

    hideCloseButtonDebounced()
  }

  function hideCloseButton() {
    closeButtonIsVisible.value = false
  }
</script>

<style lang="scss">
  .event-slideshow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--eventSlideshowBackgroundColor);
    padding: 5dvh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(desktop) {
      padding: 10dvh;
    }
  }

  .event-slideshow__image {
    width: 100%;
    height: 100%;
  }

  .event-slideshow__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    color: white;
  }

  .event-slideshow__title {
    position: absolute;
    top: 30px;
    left: 30px;
    color: white;
  }

  .event-slideshow__close-icon {
    width: 100%;
    height: 100%;
  }

  .event-slideshow__text-logo {
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: 14px;
    opacity: 0.8;

    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
  }

  .event-slideshow__settings {
    padding: 24px;
    background: black;
    border-radius: 6px;
    width: 100%;
    max-width: 400px;
    max-height: 100dvh;
    overflow: scroll;
    overflow-x: hidden;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .event-slideshow__settings .app-input__label {
    color: white;
  }

  .event-slideshow__settings-title {
    color: white;
    font-size: 24px;
  }

  .event-slideshow__error {
    margin: 8px 0 0;
  }

  .event-slideshow__toggles {
    margin: 12px 0 0;
  }

  .event-slideshow__settings-toggle {
    color: white;
  }

  .event-slideshow__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    margin: 24px 0 0;

    @include breakpoint(desktop) {
      flex-direction: row;
    }
  }

  .event-slideshow__button--disabled {
    opacity: 0.25;
  }
</style>
